export class SessionInfo
{
    user: string;
    ngStorageToken: string;
    ngStorageRefresh:string;
    ngStorageExpires:string;
 
    constructor(sessionInfo)
    {
        {
            this.user = "" || sessionInfo.user;
            this.ngStorageToken = "" || sessionInfo.ngStorageToken;
            this.ngStorageRefresh = "" || sessionInfo.ngStorageRefresh;
            this.ngStorageExpires = "" || sessionInfo.ngStorageExpires;
        }
    }
}

export enum RouteData {
    AllowedProfiles = 'AllowedProfiles',
    Profile = 'Profile',
    Assert = 'Assert'
}

export enum Profiles {
    IsAdmin = 'isAdmin',
    IsSchoolAdmin = 'isSchoolAdmin',
    IsTutor = 'isTutor',
    IsTeacher = 'isTeacher',
    IsStudent = 'isStudent',
    IsParent = 'isParent',
    OnDemand = 'onDemand',
    IsSuperAdmin = 'isSuperAdmin'
}

export enum ProfileConditions {
    IsDemoUser = 'return ((JSON.parse(sessionStorage.getItem("currentUser") || "{}").UserName || "").toLowerCase().indexOf(".tmp.") > -1) === true',
    WaliLeeEnabled = 'return !!this.schoolService.school?.StartWaliLee && this.moment() >= this.moment(this.schoolService.school?.StartWaliLee)',
    MeetGamesConditions = 'return this.userService.getPlayMeetAllConditions()',
    MeetZoneConditions = 'return this.userService.getMyZoneMeetAllConditions()'
}

/*export enum UserRoles {
    Student = 1,
    Tutor = 2,
    StudentAdmin = 3,
    OnDemand = 4,
    ParentAdmin = 5,
    SchoolAdmin = 6
}*/