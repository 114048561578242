import { GlobalService } from "./global.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { NavigationService } from "./navigation.service";
import { UserService } from "./user.service";
import { forkJoin, Subject } from "rxjs";
import * as moment from "moment";
import { MatDialog } from "@angular/material/dialog";
import { FuseDialogContinueComponent } from "@fuse/components/dialog-continue/dialog-continue.component";
import { map, tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "app/app.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentUser;
  checked_announcements = false;
  todaySessionStarted = true;
  shopPresentLoaded = false;
  baseUrl = environment.baseApi + "/connect/token";
  private s_current_user = new Subject<boolean>();
  current_user = this.s_current_user.asObservable();
  confirmDialog: any;

  constructor(
    private http: HttpClient,
    private navigationService: NavigationService,
    private userService: UserService,
    private globalService: GlobalService,
    private _matDialog: MatDialog,
    private _translateService: TranslateService,
    private _appService: AppService
  ) {
  }

  changeCurrentUser(current_user: any) {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.s_current_user.next(current_user);
  }

  check() {
    if (!sessionStorage.getItem("currentUser")) {
      this.currentUser = null;
      this.changeCurrentUser(this.currentUser);
      return this.navigationService.exit();
    }
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.changeCurrentUser(this.currentUser);
    if (!this.currentUser) return this.navigationService.exit();
    return this.currentUser;
  }

  refreshCurrentUser() {
    if (!sessionStorage.getItem("currentUser")) {
      this.currentUser = null;
      this.changeCurrentUser(this.currentUser);
    }

    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.changeCurrentUser(this.currentUser);

    return this.currentUser;
  }

  updateCurrentUserProperty(property: string, value: any, notify: boolean) {
    if (!property) {
      return;
    }

    if (!sessionStorage.getItem("currentUser")) {
      this.currentUser = null;
      this.changeCurrentUser(this.currentUser);
    }

    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.currentUser[property] = value;

    sessionStorage.setItem("currentUser", JSON.stringify(this.currentUser))

    if (notify) this.changeCurrentUser(this.currentUser);

    return this.currentUser;
  }

  ReloadUserCoins(ok = () => { }): void {
    this.userService.getUserCoins(this.currentUser.Id).subscribe(result => {
        this.currentUser.Coins = result;
        sessionStorage.setItem("currentUser", JSON.stringify(this.currentUser));
        this.changeCurrentUser(this.currentUser);
        ok();
    })
    
  }

    LoadShopPresents(force = false) {
        if (force || !this.shopPresentLoaded) {
            forkJoin([
                this.userService.getUserPresents(this.currentUser.Id),
                this.userService.getUserCoins(this.currentUser.Id)
            ])
                .subscribe((data: any[]) => {
                    this.currentUser.ShopPresents_enviados = data[0][0];
                    this.currentUser.ShopPresents_recibidos = data[0][1];
                    this.currentUser.ShopPresents_nuevos = data[0][2];
                    this.currentUser.AvatarShopPresents_recibidos = data[0][3];
                    this.currentUser.AvatarShopPresents_nuevos = data[0][4];
                    this.currentUser.Coins = data[1];
                    sessionStorage.setItem("currentUser", JSON.stringify(this.currentUser));
                    this.changeCurrentUser(this.currentUser);
                })
        }
    }

    checkLogin(username, password) {
    let requestBody = {
        username: username,
        password: encodeURIComponent(password),
        version: environment.version,
        UserAgent: navigator.userAgent,
        UserMoment: moment().add(moment().utcOffset(), 'minutes'),
        UserMomentUTC: moment().utc(),
        FingerPrint: this.userService.getFingerPrint()
    };
    return this.http.post(this.baseUrl, requestBody).pipe(tap((r: any) => this.clearSessionSpecialItemsFlag(r.id)), tap(async _ => await this._appService.getVersionForImages().toPromise()), map(this._showBadTimeRangeError));
  }

  getUserByUserNameAndPassword(username, password, ok, ko) {
    let offset = -(new Date().getTimezoneOffset() / 60);
    let requestBody = {
        username: username,
        password: encodeURIComponent(password),
        version: environment.version,
        UserAgent: navigator.userAgent,
        UserMoment: moment().add(moment().utcOffset(), 'minutes'),
        UserMomentUTC: moment().utc(),
        FingerPrint: this.userService.getFingerPrint()
    };
    this.http
      .post(this.baseUrl, requestBody).pipe(tap((r: any) => this.clearSessionSpecialItemsFlag(r.id)), tap(async _ => await this._appService.getVersionForImages().toPromise()), map(this._showBadTimeRangeError))
      .subscribe((result: any) => {
          let expired_in = new Date(
            new Date().getTime() + 1000 * result.expires_in
          ).toISOString();
          sessionStorage.setItem(
            "ngStorage-token",
            result.access_token
          );
          sessionStorage.setItem(
            "bearer",
            JSON.stringify(result.access_token)
          );
          sessionStorage.setItem(
            "ngStorage-refresh",
            JSON.stringify(result.refresh_token)
          );
          sessionStorage.setItem(
            "ngStorage-expires",
            JSON.stringify(expired_in)
          );
          this.userService
            .getUserByToken()
            .subscribe(
                (result: any) => {
                    ok(result);
                },
                (error) => {
                    ko(error);
                }
            );
        },
        (error) => {
          ko(error);
        }
      );
  }

  loginWithParent(username, password, ok, ko) {
    this.getUserByUserNameAndPassword(
      username,
      password,
      (user) => {
        if (user.IdUserTutor) {
          if (user.IdSchool) {
            ko("SCHOOL");
            return;
          }

          this.userService.getUserById(user.IdUserTutor).subscribe(
            (user: any) => {
              this.redirectByRole(
                user,
                1,
                (r) => {
                  ok(user);
                },
                (error) => {
                  ko(error);
                }
              );
            },
            (error) => {
              ko(error);
            }
          );
        } else {
          this.userService.getUserById(user.Id).subscribe(
            (user: any) => {
              this.redirectByRole(
                user,
                1,
                (r) => {
                  ok(user);
                },
                (error) => {
                  ko(error);
                }
              );
            },
            (error) => {
              ko(error);
            }
          );
        }
      },
      (error) => {
        if (!error.error.user) {
          ko(error.error);
          return;
        }

        this.userService.getUserById(error.error.user.Id).subscribe(
          (user: any) => {
            if (user.IdUserTutor) {
              if (user.IdSchool) {
                if (user.Prepaid) {
                  ko("PREPAID");
                } else {
                  ko("SCHOOL");
                }
                return;
              } else {
                ko("PARTICULAR");

                return;
              }
              
              /*this.userService
                .getUserById(user.IdUserTutor)
                .subscribe(
                  (user: any) => {
                    this.redirectByRole(
                      user,
                      1,
                      (r) => {
                        ok(user);
                      },
                      (error) => {
                        ko(error);
                      }
                    );
                  },
                  (error) => {
                    ko(error);
                  }
                );*/
            } else {
              this.userService.getUserById(user.Id).subscribe(
                (user: any) => {
                  this.redirectByRole(
                    user,
                    1,
                    (r) => {
                      ok(user);
                    },
                    (error) => {
                      ko(error);
                    }
                  );
                },
                (error) => {
                  ko(error);
                }
              );
            }
          },
          (error) => {
            ko(error);
          }
        );
      }
    );
  }

  checkLicense(username, password, callback) {
    let offset = -(new Date().getTimezoneOffset() / 60);
    
    let requestBody = {
        username: username,
        password: encodeURIComponent(password),
        version: environment.version,
        UserAgent: navigator.userAgent,
        UserMoment: moment().add(moment().utcOffset(), 'minutes'),
        UserMomentUTC: moment().utc(),
        FingerPrint: this.userService.getFingerPrint()
    };
    this.http
      .post(this.baseUrl, requestBody).pipe(tap((r: any) => this.clearSessionSpecialItemsFlag(r.id)), tap(async _ => await this._appService.getVersionForImages().toPromise()), map(this._showBadTimeRangeError))
      .subscribe((result: any) => {
          let expired_in = new Date(
            new Date().getTime() + 1000 * result.expires_in
          ).toISOString();
          sessionStorage.setItem(
            "ngStorage-token",
            result.access_token
          );
          sessionStorage.setItem(
            "bearer",
            JSON.stringify(result.access_token)
          );
          sessionStorage.setItem(
            "ngStorage-refresh",
            JSON.stringify(result.refresh_token)
          );
          sessionStorage.setItem(
            "ngStorage-expires",
            JSON.stringify(expired_in)
          );

          this.userService
          .getUserByToken()
          .subscribe(
              (result: any) => {
                callback(true);
              },
              (error) => {
                callback(false);
              }
          );
        },
        (error) => {
          callback(false);
        }
      );
  }

    reloadUser(ok = () => { }) {
        this.userService.getUserById(this.currentUser.Id).subscribe(
            (user: any) => {
                user.AdminLogin = this.currentUser.adminLogin;
                switch (user.IdRole) {
                    case 1:
                        break;
                    case 2:
                        user.IsTutor = true;
                        break;
                    case 3:
                        user.IsAdmin = true;
                        break;
                    case 4:
                        user.IsAdmin = true;
                        user.OnDemand = true;
                        break;
                    case 5:
                        user.IsTutor = true;
                        user.IsAdmin = true;
                        break;
                    case 6:
                        user.IsTutor = true;
                        user.IsSchoolAdmin = true;
                        break;
                }
                this.userService.getUserPresents(user.Id)
                    .subscribe((data: any[]) => {
                        user.ShopPresents_enviados = data[0];
                        user.ShopPresents_recibidos = data[1];
                        user.ShopPresents_nuevos = data[2];
                        user.AvatarShopPresents_recibidos = data[3];
                        user.AvatarShopPresents_nuevos = data[4];
                        
                        let token = sessionStorage.getItem("ngStorage-token");
                        if (token.substring(0, 1) != '"') token = JSON.stringify(token);
                        sessionStorage.setItem("currentUser", JSON.stringify(user));
                        sessionStorage.setItem(
                            "adminLogin",
                            JSON.stringify(user["adminLogin"])
                        );
                        sessionStorage.setItem("isLogged", "true");
                        sessionStorage.setItem("ngStorage-token", token);
                        //this._reconfigRoutes();
                        this.currentUser = user;
                        this.changeCurrentUser(this.currentUser);
                        ok();
                    })
            },
            (error) => { }
        );
    }

    login(username, password, ok, ko) {
        let offset = -(new Date().getTimezoneOffset() / 60);
        let requestBody = {
            username: username,
            password: encodeURIComponent(password),
            version: environment.version,
            UserAgent: navigator.userAgent,
            UserMoment: moment().add(moment().utcOffset(), 'minutes'),
            UserMomentUTC: moment().utc(),
            FingerPrint: this.userService.getFingerPrint()
        };
        this.http
            .post(this.baseUrl, requestBody).pipe(tap((r: any) => this.clearSessionSpecialItemsFlag(r.id)), tap(async _ => await this._appService.getVersionForImages().toPromise()), map(this._showBadTimeRangeError))
            .subscribe(
                (result: any) => {
                    let expired_in = new Date(
                        new Date().getTime() + 1000 * result.expires_in
                    ).toISOString();
                    sessionStorage.setItem(
                        "ngStorage-token",
                        result.access_token
                    );
                    sessionStorage.setItem(
                        "bearer",
                        JSON.stringify(result.access_token)
                    );
                    sessionStorage.setItem(
                        "ngStorage-refresh",
                        JSON.stringify(result.refresh_token)
                    );
                    sessionStorage.setItem(
                        "ngStorage-expires",
                        JSON.stringify(expired_in)
                    );
                    if(result.child_id){
                        this.showDialog(password, ok, ko, result.child_id)
                    }
                    else {
                        this.continueLogin(ok, ko)
                    }
                },
                (error) => {
                    ko(error);
                }
            );
    }
    continueLogin(ok, ko) {
        this.userService
            .getUserByToken()
            .subscribe(
                (result: any) => {
                    let adminLogin = result.AdminLogin;
                    let userType;
                    if (result.IdUserTutor) {
                        userType = result.IdRole == 5 ? 1 : 2;
                    } else if (!result.IdUserTutor) {
                        userType = 1;
                    }
                    result.AdminLogin = adminLogin;
                    if (result.IdRole == 1 || result.IdRole == 3) {
                        this.userService.getUserPresents(result.Id)
                            .subscribe((data: any[]) => {
                                this.shopPresentLoaded = true;
                                result.ShopPresents_enviados = data[0];
                                result.ShopPresents_recibidos = data[1];
                                result.ShopPresents_nuevos = data[2];
                                result.AvatarShopPresents_recibidos = data[3];
                                result.AvatarShopPresents_nuevos = data[4];
                                this.redirectByRole(
                                    result,
                                    userType,
                                    ok,
                                    ko
                                );
                            })
                    }
                    else {
                        this.redirectByRole(
                            result,
                            userType,
                            ok,
                            ko
                        );
                    }
                },
                (error) => {
                    ko(error);
                }
            );
    }
    showDialog(password, ok, ko, IdStudent) {
        this.confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });
        this.confirmDialog.componentInstance.title = "Modo de acceso";
        this.confirmDialog.componentInstance.margin = false;
        this.confirmDialog.componentInstance.theme = "white"
        this.confirmDialog.componentInstance.options = [{
            text: "Acceso como tutor",
            callback: () => {
                this.continueLogin(ok, ko)
            }
        }, {
            text: "Acceso como alumno",
            callback: () => {
                this.login(IdStudent, password, ok, ko)
            }
        }];
        this.confirmDialog.afterClosed().subscribe(result => {
            result();
            this.confirmDialog = null;
        });
    }
  redirectByRole(user, userType, ok, ko) {
    switch (user.IdRole) {
      case 1:
        break;
      case 2:
        user.IsTutor = true;
        break;
      case 3:
        user.IsAdmin = true;
        break;
      case 4:
        user.IsAdmin = true;
        user.OnDemand = true;
        break;
      case 5:
        user.IsTutor = true;
        user.IsAdmin = true;
        break;
      case 6:
        user.IsTutor = true;
        user.IsSchoolAdmin = true;
        break;
    }
    let token = sessionStorage.getItem("ngStorage-token");
    if (token.substring(0, 1) != '"') token = JSON.stringify(token);
    sessionStorage.setItem("currentUser", JSON.stringify(user));
    sessionStorage.setItem(
      "adminLogin",
      JSON.stringify(user["adminLogin"])
    );
    sessionStorage.setItem("isLogged", "true");
    sessionStorage.setItem("ngStorage-token", token);
    //this._reconfigRoutes();
    this.currentUser = user;
    this.changeCurrentUser(this.currentUser);
    if (
      window.navigator["connection"] &&
      window.navigator["connection"]["downlink"] < 1.5
    ) {
      this.globalService.clientError(
        "La velocidad de internet del usuario es lenta: " +
        window.navigator["connection"]["downlink"] +
        "Mbp/s ",
        user.Id
      );
    }
    if (userType == 1) {
      if (this.currentUser.DefaultPassword) {
        this.userService
          .getPasswordToken(this.currentUser.Id)
          .subscribe(
            (result: any) => {
              let token = result.token;
              this.navigationService.go(
                "/resetpasswordintern/" +
                this.currentUser.Id +
                "/" +
                token,
                { userType: userType }
              );
            },
            (error) => {
              this.navigationService.goLogin();
            }
          );
        return;
      }
        if (user.IsTutor || user.IsAdmin) {
            this.navigationService.go("/school")
            ok();
        }
         else {
        this.navigationService.goLogin();
        ko("Usuario inválido");
      }
    } else {
      if (user.IsTutor) {
        this.navigationService.goLogin();
        ko("Usuario inválido");
        return;
      }
      if (this.currentUser.DefaultPassword) {
        this.userService
          .getPasswordToken(this.currentUser.Id)
          .subscribe(
            (result: any) => {
              let token = result.token;
              this.navigationService.go(
                "/resetpasswordintern/" +
                this.currentUser.Id +
                "/" +
                token,
                { userType: userType }
              );
            },
            (error) => {
              this.navigationService.goLogin();
            }
          );
      } else if (this.currentUser.OnDemand) {
        this.navigationService.go("/demand", {});
      } else {
        this.navigationService.go("/student/session", {});
      }

      ok();
    }
  }

  getHeaders() {
    let credentials = "Walinwa" + ":" + "walSecrinwa";
    var headerValue = btoa(credentials);
    let headers = new HttpHeaders();
    headers = headers.append("Access-Control-Allow-Origin", "*");
    headers = headers.append(
      "Access-Control-Allow-Methods",
      "GET, POST, DELETE, PUT"
    );
    headers = headers.append(
      "Access-Control-Allow-Headers",
      "X-Requested-With,content-type"
    );
    headers = headers.append("Access-Control-Allow-Credentials", "true");
    headers = headers.append(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    headers = headers.append("Authorization", "Basic " + headerValue);
    return headers;
  }

  redirect() { }

  /*public _reconfigRoutes(): void {    
    const token: string = sessionStorage.getItem("ngStorage-token");

    if (token) {
      const tokenDecoded = this._helper.decodeToken(token);
      let config: Routes = appRoutes.map(r => Object.assign({}, r));;

      if (tokenDecoded[Profiles.IsTeacher] === 'true') {
        (config || []).forEach(route => this._deleteRoutesOfType<ParentComponent>(ParentComponent, route));
      } else if (tokenDecoded[Profiles.IsParent] === 'true') {
        (config || []).forEach(route => this._deleteRoutesOfType<TeacherComponent>(TeacherComponent, route));
      }

      this.router.resetConfig(config);
    }
  }

  private _deleteRoutesOfType<T>(type: Type<T>, route: Route): boolean {
if (route.data?.entryComponent) {debugger;}
    if (route.data?.entryComponent === type) {
      return true;
    } else {
      (route.children || []).forEach(childRoute => {
        let found = this._deleteRoutesOfType(type, childRoute);

        if (found) {
          route.children = route.children.filter(c => c.component !== childRoute.component);
        }
      });

      return false;
    }
  }*/

  
  private _showBadTimeRangeError = (r: any) => { 
    if (r.badTimeRange) {
        let confirmDialog = this._matDialog.open(FuseDialogContinueComponent, {
            disableClose: true
        });

        confirmDialog.componentInstance.title = "Hora del dispositivo no válida"
        confirmDialog.componentInstance.message1 = "Revisa la fecha y hora de tu dispositivo. La hora debe ser la hora actual del país en el que te encuentras.";        
        confirmDialog.componentInstance.textButton = this._translateService.instant('ACCEPT');
        confirmDialog.componentInstance.margin = false;
        throw new Error();
    }     
    return r;
  };

  public clearSessionSpecialItemsFlag(idUser: number) {
    const specialItemsKey = `newSpecialItemsAvailable${idUser}`;

    sessionStorage.removeItem(specialItemsKey);
}

  /*async getSASToken(){
    const response = await this.http.get(environment.baseApi + "/connect/generateSasToken").toPromise();
    return response;
  }*/
}
