import { Injectable, isDevMode } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpResponse, HttpUserEvent, HttpProgressEvent, HttpErrorResponse, HttpStatusCode, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SharedService } from '../shared/shared.service';
import { BlobResponse } from '../shared/state/models/blob-response-model';
import { environment as devEnvironment } from 'environments/environment.dev';
import { environment as prodEnvironment } from 'environments/environment.prod';
import { environment } from 'environments/environment';
import * as moment from 'moment-timezone';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

    constructor(private sharedService: SharedService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        if (req.url.includes("dictations/index.php")) return next.handle(req);

        let token = "";
        let autorize = !req.url.includes("/images/") && !req.url.includes("googleapis") && !req.url.includes("/i18n/");
        
        if (!environment.production && req.url.includes(prodEnvironment.filesUrl) && req.url.includes("/images/")) {
            const newUrl = req.url.replace(prodEnvironment.filesUrl, devEnvironment.filesUrl);

            req = req.clone({ url: newUrl });
        }

        const zone = moment.tz.guess();

        req = req.clone({ setHeaders: { 'Time-Zone': zone } });

        sessionStorage.setItem('Time-Zone', zone);

        if (req.method !== 'GET') {
            req = req.clone({ setHeaders: { 'ngsw-bypass': 'true' } });
        }
        else if (((req.params && req.params.get('ngsw-bypass') === null) || !req.params) && req.urlWithParams.indexOf('ngsw-bypass') === -1) {
            if (!req.url.includes("googleapis"))
                req = req.clone({ params: (req.params ? req.params : new HttpParams()).set('ngsw-bypass', 'true') });           
        }

        try {
            token = sessionStorage.getItem('ngStorage-token');

            if(token.indexOf('"') != -1) token = token.substring(1, token.length - 1);
        } catch (e) {            
            return next.handle(req);
        }

        if (token) token = token.replace(/['"]+/g, '');
    
        req = autorize ? req.clone({ setHeaders: { Authorization: "Bearer " + token } }) : req;

        return next.handle(req).pipe(catchError(error => {           
            if (error instanceof HttpErrorResponse) {
                if ([HttpStatusCode.Ok, HttpStatusCode.Created].indexOf(error.status) > -1 && error.headers.get('Walinwa-Encoded') === 'true') {
                    return of(new HttpResponse({
                        body: this.sharedService.decodeJSONResponse(error.error.text || error.error),
                        status: error.status,
                        statusText: error.statusText
                    }));
                }

                /*if (isDevMode()) {
                    if (error.status === HttpStatusCode.Unauthorized || error.status === HttpStatusCode.Forbidden) {
                        alert(`No autorizado a ${req.url}`);
                    }
                }*/

                return throwError(error);  
            }}), map((event) => {
                if (event instanceof HttpResponse && (event.body instanceof Blob || event.body instanceof ArrayBuffer)) {
                    const disposition = event.headers.get('content-disposition');
                    const fileName = disposition && disposition.indexOf('attachment') !== -1 && disposition.split('filename=')[1].split(';')[0].replace(/"/g, '');
                    const type = event.headers.get('content-type');
                    const blob = new Blob([event.body], { type });
                    const responseBody: BlobResponse = { file: blob, fileName };

                    return event.clone({ body: responseBody });
                }
                else if (!(event instanceof HttpResponse)) {
                    return event;
                }

                if (event.headers.get('Walinwa-Encoded') === 'true')
                    return event.clone({ body: this.sharedService.decodeJSONResponse(event.body) });
                
                return event;
            })
        );
    }
}
