import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonalizedTestStudentComponent } from './personalized-test.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'app/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { WeeklyTestComponent } from './weekly-test/weekly-test/weekly-test.component';
import { WeeklyTestGameComponent } from './weekly-test/weekly-test-game/weekly-test-game.component';
import { GameSelectorModule } from '../mini-games/game-selector/game-selector.module';

@NgModule({
    declarations: [
        PersonalizedTestStudentComponent,
        WeeklyTestComponent,
        WeeklyTestGameComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        SharedModule,
        FuseSharedModule,
        MatCheckboxModule,
        MatButtonModule,
        GameSelectorModule
    ],
    exports: [
        PersonalizedTestStudentComponent,
        WeeklyTestComponent,
        WeeklyTestGameComponent
    ]
})
export class PersonalizedTestModule { }
