import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class AdminMessageService {

    baseUrl = environment.baseApi + '/api/AdminMessages';

    constructor(private http: HttpClient) {
    }

    getAvailableMessages(searchText, num) {
        var params = { searchText: searchText, number: num };
        return this.http.post(this.baseUrl + "/searchMessage", params);
    }

    updateMessage(oldHtml, html) {
        return this.http.post(`${this.baseUrl}/updateMessage`, { OldHtml: oldHtml, Html: html });
    }

    addMessage(idUser, idMsg, startDate, endDate) {
        var params = { idUser: idUser, idMsg: idMsg, startDate: startDate, endDate: endDate  };
        return this.http.post(this.baseUrl + "/addMessage", params);
    }

    addAdminMessage(html) {
        var params = { html: html};
        return this.http.post(this.baseUrl + "/addAdminMessage", params);
    }

    addWalis(idUser, amount, desc) {
        var params = { idUser: idUser, amount: amount, desc: desc };
        return this.http.post(this.baseUrl + "/addWalis", params);
    }

    getPendingMessages(idUser/*, currentDate*/) {
        //var params = { currentDate: currentDate};
        return this.http.post(this.baseUrl + "/pendingMessages/" + idUser, null);//params);
    }; 

    viewMessage(idMessage, idUser, date, teacherMessage) {            

        var offset = -(new Date().getTimezoneOffset() / 60);
        var params = { date: date, timeZone: offset };
        
        return this.http.post(this.baseUrl + "/viewMessage/" + idMessage + "/idUser/" + idUser + "/teacher/" + teacherMessage , params);
    };

    getUserMessages(idUser) {
        var params = { idUser: idUser };
        return this.http.post(this.baseUrl + "/getUserMessages", params);
    };

}
