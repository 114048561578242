import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector   : 'dialog-continue',
    templateUrl: './dialog-continue.component.html',
    styleUrls  : ['./dialog-continue.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseDialogContinueComponent
{
    public TitleMode = TitleMode;
    public TitleColor = TitleColor;
    public message1: string;
    public message2: string;
    public message3: string;
    public textButton: string;
    public title :string;
    public margin:boolean;
    public options;
    public isReport = false;
    public theme = '';//"blue";
    public space : boolean = false;
    public canClose = false
    public active: boolean = true;
    public disableButtons = false;
    public messages = [];
    public config: FuseDialogContinueComponentConfiguration

    constructor(
        public dialogRef: MatDialogRef<FuseDialogContinueComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FuseDialogContinueComponentConfiguration)
    {
        this.config = {
            titleMode: TitleMode.Default,
            titleColor: TitleColor.Default,
            image: null,
            ...data
        };
    }

    selectOption() {
        let element: any = document.getElementById("selectedOption");
        let selectedOptionText = element.value;
        let selectedOption = this.options.find(o => o.text == selectedOptionText);
        this.dialogRef.close(selectedOption.callback)
    }
}

export interface FuseDialogContinueComponentConfiguration {
    titleMode?: TitleMode;
    titleColor?: TitleColor;
    image?: string;
}

export enum TitleMode {
    Default,
    Centered,
}

export enum TitleColor {
    Default,
    Orange,
}