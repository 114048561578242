import { SchoolService } from 'app/services/school.service';
import { Component, HostListener, OnInit, OnDestroy,
    ViewEncapsulation,
    AfterViewInit, } from '@angular/core';
import { Router } from '@angular/router';
import { AnnouncementService } from 'app/services/announcement.service';
import { AuthService } from 'app/services/auth.service';
import { NavigationService } from 'app/services/navigation.service';
import * as moment from 'moment';
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: "app-teacher",
    templateUrl: "./teacher.component.html",
    styleUrls: ["./teacher.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class TeacherComponent implements OnInit, AfterViewInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;
    dataLoaded = false;
    showPopUp = false;
    announcement;
    poUpImg;
    reportEnabled = true;
    allActivitiesDisabled = false;
    startingHeight = window.innerHeight;
    allComplementsDisabled: boolean = false;
    walileeEnabled: boolean = false;

    // Custom
    // isMobile: boolean;

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        // if (
        //     this.appService.mobileDetect().IsAndroidTablet &&
        //     this.startingHeight > event.target.innerHeight
        // ) {
        //     document.getElementById(
        //         "walinwa-container-sidebar"
        //     ).style.paddingTop = "4.6em";
        // } else {
        //     document.getElementById(
        //         "walinwa-container-sidebar"
        //     ).style.paddingTop = "2em";
        // }

        
    }
  constructor(
    private router: Router, 
    private navigationService: NavigationService, 
    private authService: AuthService,
    private announcementService: AnnouncementService,
    private schoolService: SchoolService,
    ) { 
        this._unsubscribeAll = new Subject();
      this.poUpImg = null;
    }
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() {
        this.dataLoaded = true;
        this.reportEnabled =
            this.schoolService.school?.Report == null
                ? true
                : this.schoolService.school.Report;
        this.allActivitiesDisabled =
            !this.schoolService.school?.DeductionText &&
            !this.schoolService.school?.Dictation &&
            !this.schoolService.school?.ReadingText;
        this.getAnnouncement();
        this.allComplementsDisabled =
        !this.schoolService.school?.PredefinedSession &&
        !this.schoolService.school?.Exam &&
        !this.schoolService.school?.ControlSession &&
        !this.schoolService.school?.PersonalizedTest;

        this.walileeEnabled = !!this.schoolService.school?.StartWaliLee && moment() >= moment(this.schoolService.school?.StartWaliLee);
    }

    ngAfterViewInit(){
        
    }

    getAnnouncement() {        
        this.announcementService
            .getAnnouncement(this.authService.currentUser.Id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (response) => {
                    this.announcement = response;
                    this.checkReward();
                },
                (error) => {
                    console.error(error);
                }
            );
    }

    getImg(): string {
        return "https://walinwa.blob.core.windows.net/images" + this.announcement.ImgName+ "?ngsw-bypass=true";
    }
    
    /*getImgName(): string {
        //example this.announcement.ImgName = /announcements/avisoAyuda.png
        return this.announcement.ImgName.split("/")[2];
    }*/
    checkReward() {
        if (this.announcement) {
            var spCookie;
            if (this.announcement.CookieName) {
                //SpecialDate
                spCookie = sessionStorage.getItem(
                    this.announcement.CookieName +
                        this.authService.currentUser.Id
                );
                if (
                    spCookie == null &&
                    moment()
                        .startOf("day")
                        .isSame(
                            moment(this.announcement.ReleaseDate).startOf("day")
                        )
                ) {
                    this.showPopUp = true;
                    var now = new Date(),
                        // this will set the expiration to 6 months
                        exp = new Date(
                            now.getFullYear(),
                            now.getMonth() + 6,
                            now.getDate()
                        );
                    sessionStorage.setItem(
                        this.announcement.CookieName +
                            this.authService.currentUser.Id,
                        this.authService.currentUser.Id
                    );
                }
            } else {
                this.showPopUp = true;
                // if (!this.adminLogin) this.announcementService.setAnnouncementUser(this.announcement.IdAnnouncement, this.authService.currentUser.Id).subscribe()
            }
            // this.announcement = null;
        }
    }
    closePopUp() {
        // this.overlay.className = '';
        // this.popup.className = '';
        this.showPopUp = false;
        // if (this.authService.currentUser.LastRewardDate == null || moment(this.authService.currentUser.LastRewardDate).month() != moment().month()) {
        //   this.extraRewardService.getLastMonthReward(this.authService.currentUser.Id).subscribe(
        //     result => {
        //       this.successLastMonthReward(result)
        //     },
        //     error => {
        //       this.errorCallback()
        //     }
        //   )
        // }
    }

    exit() {
        this.navigationService.exit(true);
    }

    inConfig() {
        return this.router.url.includes("config");
    }

    inActivities() {
        return this.router.url.includes("/school/activities");
    }
    inComplements() {
        return this.router.url.includes("complements");
    }

    inWalilee() {
        return this.router.url.includes("walilee");
    }

    inReport() {
        return this.router.url.includes("tracking");
    }

    GoToHelpPageAlumnos() {
        let popupHelpLink = document.getElementById("HelpPageMobileHyperlink");
        let newURL = "./assets/pdf/ayuda_profesores.pdf#page=1";
        popupHelpLink?.setAttribute("href", newURL);
    }

    GoToHelpPageConfiguracion() {
        let popupHelpLink = document.getElementById("HelpPageMobileHyperlink");
        let newURL = "./assets/pdf/ayuda_profesores.pdf#page=8";
        popupHelpLink?.setAttribute("href", newURL);
    }

    GoToHelpPageActividades() {
        let popupHelpLink = document.getElementById("HelpPageMobileHyperlink");
        let newURL = "./assets/pdf/ayuda_profesores.pdf#page=14";
        popupHelpLink?.setAttribute("href", newURL);
    }

    GoToHelpPageComplementos() {
        let popupHelpLink = document.getElementById("HelpPageMobileHyperlink");
        let newURL = "./assets/pdf/ayuda_profesores.pdf#page=17";
        popupHelpLink?.setAttribute("href", newURL);
    }

    GoToHelpPageSeguimiento() {
        let popupHelpLink = document.getElementById("HelpPageMobileHyperlink");
        let newURL = "./assets/pdf/ayuda_profesores.pdf#page=22";
        popupHelpLink?.setAttribute("href", newURL);
    }

    GoToHelpPageMensajes() {
        let popupHelpLink = document.getElementById("HelpPageMobileHyperlink");
        let newURL = "./assets/pdf/ayuda_profesores.pdf#page=24";
        popupHelpLink?.setAttribute("href", newURL);
    }
}
