import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GameSelectorComponent } from './game-selector.component';

@NgModule({
    declarations: [GameSelectorComponent],
    imports: [
        CommonModule,
        TranslateModule.forChild()
    ],
    exports: [
        GameSelectorComponent
    ]
})
export class GameSelectorModule { }
