import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class ReportService {

    baseUrl = environment.baseApi + '/api/Report';

    constructor(private http: HttpClient) {
    }

    getPdf(reportNameP, pdfNameP, parametersP) {
        pdfNameP = pdfNameP.replace(/[^\w]+/g, "_")
        const params = this._addTimeZoneParam({ reportName: reportNameP, pdfName: pdfNameP, parameters: parametersP });

        return this.http.post(this.baseUrl + "/getPdfReport", params, { observe: 'response', responseType: 'blob' });
    }

    getExcel(reportNameP, excelNameP, parametersP) {
        excelNameP = excelNameP.replace(/[^\w]+/g, "_")
        const params = this._addTimeZoneParam({ reportName: reportNameP, excelName: excelNameP, parameters: parametersP });

        return this.http.post(this.baseUrl + "/getExcelReport", params, { observe: 'response', responseType: 'blob' });        
    }

    getHtml(reportNameP, parametersP) {
        const params = this._addTimeZoneParam({ reportName: reportNameP, parameters: parametersP });

        return this.http.post(this.baseUrl + "/getHtmlReport", params);
    }

    getZip(reportNameP, zipNameP, classIdP, parametersP) {
        zipNameP = zipNameP.replace(/[^\w]+/g, "_")

        const params = this._addTimeZoneParam({ reportName: reportNameP, zipName: zipNameP, classId: classIdP, parameters: parametersP });

        return this.http.post(this.baseUrl + "/getZipClassReport", params, { observe: 'response', responseType: 'arraybuffer' });
    }

    private _addTimeZoneParam(params: any): any{
        switch (params.reportName) {
            case 'dictationHistory':
            case 'evaReadingText':
            case 'evaCalificaSchool':
            case 'studentHistory':
            case 'studentLastWeek':
            case 'studentRegularity':
            case 'studentSession':
                params.parameters.push({ timeZone: 'yes'});

                break;
        }

        return params;
    }
}
