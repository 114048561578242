import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { WalinwaUpdateComponent } from './walinwa-update/walinwa-update.component';
import { TpvComponent } from './tpv/tpv.component';
import { PlankComponent } from './plank/plank.component';
import { MessagePopupComponent } from './message-popup/message-popup.component';
import { DatagridToolbarComponent } from './datagrid-toolbar/datagrid-toolbar.component';
import { DailyTextComponent } from './daily-text/daily-text.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CalCustomizedSessionComponent } from './cal-customized-session/cal-customized-session.component';
import { CalDeductionTextComponent } from './cal-deduction-text/cal-deduction-text.component';
import { CalDictationsComponent } from './cal-dictations/cal-dictations.component';
import { CalHearingTextComponent } from './cal-hearing-text/cal-hearing-text.component';
import { CalReadingTextComponent } from './cal-reading-text/cal-reading-text.component';
import { SharedModule } from 'app/shared.module';
import { PersonalizedTestModule } from 'app/main/student/personalized-test/personalized-test.module';
import { AvatarFileModule } from 'app/main/student/avatar-file/avatar-file.module';
import { DxButtonModule, DxDataGridModule, DxListModule, DxTemplateModule } from 'devextreme-angular';
import { WaliLetrasComponent } from './wali-letras/wali-letras.component';
import { ToolTipModule } from './tooltip/tooltip.module';



@NgModule({
    declarations: [
        WalinwaUpdateComponent,
        TpvComponent,
        PlankComponent,
        MessagePopupComponent,
        DailyTextComponent,
        DatagridToolbarComponent,
        CalendarComponent,
        CalReadingTextComponent,
        CalHearingTextComponent,
        CalDictationsComponent,
        CalDeductionTextComponent,
        CalCustomizedSessionComponent,
        WaliLetrasComponent
    ],

    imports: [
        CommonModule,
        RouterModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        FuseSharedModule,
        SharedModule,
        ToolTipModule,
        PersonalizedTestModule,
        AvatarFileModule,
        DxDataGridModule,
        DxTemplateModule,
        DxButtonModule,
        DxListModule,
    ],
    exports: [
        WalinwaUpdateComponent,
        TpvComponent,
        PlankComponent,
        MessagePopupComponent,
        DailyTextComponent,
        DatagridToolbarComponent,
        CalendarComponent,
        CalReadingTextComponent,
        CalHearingTextComponent,
        CalDictationsComponent,
        CalDeductionTextComponent,
        CalCustomizedSessionComponent,
        WaliLetrasComponent,
        ToolTipModule
    ]
})
export class ComponentsModule { }
